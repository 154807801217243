const COOKIE_BAR_SELECTOR = ".js-cookie-bar";
const STORAGE_KEY = "accepts_cookies";

export const enhancer = (el) => {
  if (!window.localStorage || localStorage.getItem(STORAGE_KEY)) {
    return;
  }
  // Needed for a smooth animation.
  el.style.display = "flex";
  window.setTimeout((e) => el.setAttribute("aria-hidden", "false"), 1000 / 60);
};

export const handler = (el, e) => {
  localStorage.setItem(STORAGE_KEY, "true");

  const cookieBar = document.querySelector(COOKIE_BAR_SELECTOR);
  if (!cookieBar) {
    return;
  }

  cookieBar.setAttribute("aria-hidden", "true");
};
