import { enhance, handle } from "@grrr/hansel";
// Import Polyfills.
import "array-from-polyfill";
import "intersection-observer";
import Promise from "promise-polyfill";

import { enhancer as appImpressionsCounterEnhancer } from "./components/app-impressions-counter";
import { enhancer as appImpressionsScrollerEnhancer } from "./components/app-impressions-scroller";
import { enhancer as cookieBar } from "./components/cookie-bar";
import { enhancer as formValidator } from "./components/form-validator";
import { enhancer as isInView } from "./components/is-in-view";
import { enhancer as objectFit } from "./components/object-fit";
import { enhancer as playPauseToggle } from "./components/play-pause-toggle";
import {
  enhancer as rollingTextBanner,
  placeRollingTextBanner,
} from "./components/rolling-text-banner";

import { handler as appImpressionsCounterToggle } from "./components/app-impressions-counter";
import { handler as cookieBarAccept } from "./components/cookie-bar";

// Polyfill Promise.
if (typeof window.Promise === "undefined") {
  window.Promise = Promise;
}

enhance(document.documentElement, {
  appImpressionsCounterEnhancer,
  appImpressionsScrollerEnhancer,
  cookieBar,
  formValidator,
  isInView,
  objectFit,
  placeRollingTextBanner,
  playPauseToggle,
  rollingTextBanner,
});

handle(document.documentElement, {
  appImpressionsCounterToggle,
  cookieBarAccept,
});
